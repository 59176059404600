import { useEffect, useState } from "react";
import Banner from "../components/Banner";
import BannerToow from "../components/BannerToow";
import CategoryCard from "../components/CategoryCard";
import ProductCard from "../components/ProductCard";

import BannerSlider from "./BannerSlider";
import BestSelling from "./BestSelling";
import FeaturedProduct from "./FeaturedProduct";
// import ReviewCardPage from "./ReviewCardPage";

const Home = () => {
  return (
    <div className=" p-4 md:p-0">
      <BannerSlider />
      <CategoryCard />
      <BestSelling />
      {/* <BannerToow /> */}
      <ProductCard />
      {/* <Banner /> */}
      <FeaturedProduct />
      {/* {<ReviewCardPage categoryProduct={categoryProduct} />} */}
    </div>
  );
};

export default Home;
