import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import axios from "axios";

const initialState = {
  items: [],
  status: null,
};

// data fatching

export const pageFatching = createAsyncThunk("page/pageFatching", async () => {
  const res = axios.get(`${process.env.REACT_APP_URL}/api-pages`);

  return res.data;
});

export const pageSlice = createSlice({
  name: "page/pageFatching",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(pageFatching.pending, (state, action) => {
      state.status = "loading...";
    });
    builder.addCase(pageFatching.fulfilled, (state, action) => {
      state.status = "";
      state.items = action.payload;
    });
    builder.addCase(pageFatching.rejected, (state, action) => {
      state.status = "Something Went Wrong";
    });
  },
});

export default pageSlice.reducer;
